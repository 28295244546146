import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import BlankLayout from '../layouts/blank-layout.vue'
import TerminalLayout from '../layouts/TerminalLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

import { useAuthStore } from '@/stores/auth.store'
import { useMutationStore } from '@/stores/mutation.store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },

  {
    name: 'demo',
    path: '/demo',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/demo/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/demo/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/demo/preferences/Preferences.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/demo/users/UsersPage.vue'),
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/demo/projects/ProjectsPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/demo/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/demo/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/demo/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/demo/faq/FaqPage.vue'),
      },
    ],
  },

  {
    name: '',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, [ ], '401') },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, [ ], '401') },
        component: () => import('../pages/alino-office/dashboard/Dashboard.vue'),
      },
    ],
  },

  {
    path: '/article',
    name: 'article_container',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'article'], '401') },
    children: [
      {
        name: 'article',
        path: 'overview',
        component: () => import('../pages/alino-office/article/article/Article.vue'),
      },
      {
        name: 'surface',
        path: 'surface',
        component: () => import('../pages/alino-office/article/article_surface/ArticleSurface.vue'),
      },
      {
        name: 'material',
        path: 'material',
        component: () => import('../pages/alino-office/article/article_material/ArticleMaterial.vue'),
      },
      {
        name: 'category',
        path: 'category',
        component: () => import('../pages/alino-office/article/article_category/ArticleCategory.vue'),
      },
      {
        name: 'production-instructions',
        path: 'production-instructions',
        component: () => import('../pages/alino-office/article/article_production_instructions/ArticleProductionInstructions.vue'),
      },
    ],
  },

  {
    path: '/assets',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'assets'], '401') },
    children: [
      {
        name: 'company',
        path: 'company',
        component: () => import('../pages/alino-office/assets/company/Company.vue'),
      },
      {
        name: 'customer',
        path: 'customer',
        component: () => import('../pages/alino-office/assets/customer/Customer.vue'),
      },
      {
        name: 'supplier',
        path: 'supplier',
        component: () => import('../pages/alino-office/assets/supplier/Supplier.vue'),
      },
    ]
  },

  {
    path: '/operations',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'operations'], '401') },
    children: [
      {
        name: 'compound',
        path: 'compound',
        component: () => import('../pages/alino-office/operations/bath/Bath.vue'),
      },
      {
        name: 'bath',
        path: 'bath',
        component: () => import('../pages/alino-office/operations/compound/Compound.vue'),
      },
    ]
  },

  {
    path: '/vacation-planning',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'vacation-planning'], '401') },
    children: [
      {
        name: 'vacation-planning-overview',
        path: 'overview',
        component: () => import('../pages/alino-office/vacation-planning/overview/Overview.vue'),
      },
      {
        name: 'vacation-planning-application',
        path: 'application',
        component: () => import('../pages/alino-office/vacation-planning/application/Application.vue'),
      },
      {
        name: 'vacation-planning-examination',
        path: 'examination',
        component: () => import('../pages/alino-office/vacation-planning/examination/Examination.vue'),
      },
    ]
  },

  {
    path: '/tasks',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'tasks'], '401') },
    children: [
      {
        name: 'tasks-overview',
        path: 'overview',
        component: () => import('../pages/alino-office/tasks/overview/Overview.vue'),
      },
      {
        name: 'tasks-kanban',
        path: 'kanban',
        component: () => import('../pages/alino-office/tasks/kanban/Kanban.vue'),
      },
      {
        name: 'tasks-my-tasks',
        path: 'my-tasks',
        component: () => import('../pages/alino-office/tasks/my-tasks/MyTasks.vue'),
      },
    ]
  },
  
  {
    path: '/time-tracking',
    name: 'time_tracking',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'time-tracking'], '401') },
    children: [
      {
        name: 'time-tracking-overview',
        path: 'overview',
        component: () => import('../pages/alino-office/time-tracking/overview/Overview.vue'),
      },
      {
        name: 'time-tracking-track',
        path: 'track',
        component: () => import('../pages/alino-office/time-tracking/track/Track.vue'),
      },
      {
        name: 'time-tracking-sick-notes',
        path: 'sick-notes',
        component: () => import('../pages/alino-office/time-tracking/sick-notes/SickNotes.vue'),
      },
      {
        name: 'time-tracking-reports',
        path: 'reports',
        component: () => import('../pages/alino-office/time-tracking/reports/Reports.vue'),
      },
    ],
  },

  {
    path: '/time-tracking/terminal',
    name: 'time_tracking/terminal',
    component: TerminalLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'time-tracking-terminal'], '401') },
    children: [
      {
        name: 'terminal',
        path: '',
        component: () => import('../pages/alino-office/time-tracking/terminal/Terminal.vue'),
      },
    ],
  },

  {
    path: '/orders',
    name: 'orders',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'orders'], '401') },
    children: [
      {
        name: 'orders-overview',
        path: 'overview',
        component: () => import('../pages/alino-office/orders/overview/Overview.vue'),
      },
      {
        name: 'orders-orders',
        path: 'orders',
        component: () => import('../pages/alino-office/orders/orders/Orders.vue'),
      },
      {
        name: 'orders-offers',
        path: 'offers',
        component: () => import('../pages/alino-office/orders/offers/Offers.vue'),
      },
      {
        name: 'orders-planning',
        path: 'planning',
        component: () => import('../pages/alino-office/orders/planning/Planning.vue'),
      },
      {
        name: 'orders-delivery-slips',
        path: 'delivery-slips',
        component: () => import('../pages/alino-office/orders/delivery-slips/DeliverySlips.vue'),
      },
    ],
  },

  {
    path: '/wms',
    name: 'wms',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'wms'], '401') },
    children: [
      {
        name: 'wms-overview',
        path: 'overview',
        component: () => import('../pages/alino-office/wms/overview/Overview.vue'),
      },
      {
        name: 'wms-management',
        path: 'management',
        component: () => import('../pages/alino-office/wms/management/Management.vue'),
      },
      {
        name: 'wms-administration',
        path: 'administration',
        component: () => import('../pages/alino-office/wms/administration/Administration.vue'),
      },
    ],
  },

  {
    path: '/accounting',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'general'], '401') },
    children: [
      {
        name: 'receivable',
        path: 'receivable',
        component: () => import('@/pages/alino-office/accounting/receivable/Receivable.vue'),
      },
      {
        name: 'invoice',
        path: 'invoice',
        component: () => import('@/pages/alino-office/accounting/invoice/Invoice.vue'),
      },
      {
        name: 'bank-transfers',
        path: 'bank-transfers',
        component: () => import('@/pages/alino-office/accounting/bank-transfers/BankTransfers.vue'),
      },
      {
        name: 'dunning',
        path: 'dunning',
        component: () => import('@/pages/alino-office/accounting/dunning/Dunning.vue'),
      },
      {
        name: 'reminder',
        path: 'reminder',
        component: () => import('@/pages/alino-office/accounting/reminder/Reminder.vue'),
      },
      {
        name: 'export',
        path: 'export',
        component: () => import('@/pages/alino-office/accounting/export/Export.vue'),
      },
    ]
  },

  {
    path: '/media',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'media'], '401') },
    children: [
      {
        name: 'media',
        path: '',
        component: () => import('@/pages/alino-office/media/Media.vue'),
      },
    ]
  },

  {
    path: '/operational-planning',
    name: 'operational-planning',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'wms'], '401') },
    children: [
      {
        name: 'operational-planning-diary',
        path: 'diary',
        component: () => import('../pages/alino-office/operational-planning/diary/Diary.vue'),
      },
      {
        name: 'operational-planning-maintenance-plans',
        path: 'maintenance-plans',
        component: () => import('../pages/alino-office/operational-planning/maintenance-plans/MaintenancePlans.vue'),
      },
      {
        name: 'operational-planning-cleaning-schedules',
        path: 'cleaning-schedules',
        component: () => import('../pages/alino-office/operational-planning/cleaning-schedules/CleaningSchedules.vue'),
      },
    ],
  },

  {
    path: '/settings',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'settings'], '401') },
    children: [
      {
        name: 'settings',
        path: '',
        component: () => import('@/pages/alino-office/settings/Settings.vue'),
      },
    ]
  },

  {
    name: 'cms',
    path: '/cms',
    component: AppLayout,
    children: [
      {
        name: 'cms/media-library',
        path: 'media-library',
        component: () => import('@/pages/alino-office/cms/media-library/MediaLibrary.vue'),
        beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'user', 'cms'], '401') },
      },
    ]
  },

  {
    name: 'analytics',
    path: '/analytics',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin', 'analytics'], '401') },
    children: [
      {
        name: 'analytics/visitor',
        path: 'visitor',
        component: () => import('@/pages/alino-office/analytics/visitor/VisitorAnalytics.vue'),
      },
    ]
  },

  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    beforeEnter: (to:any, from:any, next:any) => { isAllowed(to, from, next, ['admin'], '401') },
    children: [
      {
        name: 'admin/user',
        path: 'user',
        component: () => import('@/pages/alino-office/admin/user/User.vue'),
      },
      {
        name: 'admin/settings',
        path: 'settings',
        component: () => import('@/pages/alino-office/admin/settings/Settings.vue'),
      },
    ]
  },

  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },

  {
    name: 'wip-container',
    path: '/wip',
    component: AppLayout,
    children: [
      {
        name: 'wip',
        path: '',
        component: () => import('../pages/wip/WiP.vue'),
      },
    ]
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    name: '401',
    path: '/401',
    component: () => import('../pages/401.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

const isAllowed = (to:any, from:any, next:any, permissions:Array<string>, reditectTo:string) => {
  const authStore = useAuthStore()

  if (to.name == 'login') {
    if(authStore?.token) {
      next({ name: '' })
    } else {
      next();
    }
  } else {
    let check = checkPermissions(permissions)

    if(check.allowed && authStore?.token) {
      next();
    } else {
      if(authStore?.token) {
        next({ name: reditectTo })
      } else {
        next({ name: 'login' })
      }
    }
  }
}

const checkPermissions = (permissions:Array<string>) => {
  const authStore = useAuthStore()

  // return { allowed: true, redirectTo: null }

  if(authStore?.user?.role?.name == 'admin') {
    return { allowed: true, redirectTo: null };
  }

  if(permissions.length == 0) {
    return { allowed: true, redirectTo: null };
  } else if(permissions.length > 0) {
    if(authStore?.token && authStore?.user?.role) {
      for(let permission of permissions) {
        if(authStore?.user?.role?.permissions.map((data: any) => { return data.name }).indexOf(permission) > -1) {
          return { allowed: true, redirectTo: null };
        }
      }

      return { allowed: false, redirectTo: '' };
    } else {
      return { allowed: false, redirectTo: 'login' };
    }
  } else {
    return { allowed: false, redirectTo: '' };
  }
}

router.beforeEach(async (to:any, from:any, next:any) => {
  const authStore = useAuthStore()
  const mutationStore = useMutationStore();

  let redirect = mutationStore.auth_guard({name: to?.name, query: to?.query});

  if(!redirect) {
    next(false);
  } else {
    if (to.name == 'login') {
      if(authStore?.token) {
        next();
      } else {
        next();
      }
    } else {
      next();
    }
  }
})

export default router
